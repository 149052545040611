<template>
  <v-container fluid>
    <h1 class="font-weight-light mb-2 headline">
      Salle sociale
    </h1>
  </v-container>
</template>
<script>
  export default {
    mounted () {
    },
  }
</script>
